import { undoable, withBi, absorbException } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import CoreApi from '../core-api'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_LIMIT_MESSAGE,
  FIELDS,
} from '../../../constants/roles'
import { getExtraMessageText } from '../services/form-service'
import { LinkTypes, MediaTypes } from '../../../panels/submit-settings-panel/constants'
import { MissingField } from '../../../constants/field-types'
import {
  FormsFieldPreset,
  FormPlugin,
  SecondsToResetDefaults,
  SuccessActionTypes,
  LimitTypes,
} from '@wix/forms-common'
import { EMPTY_EMAIL_ID, innerText, isNotEmptyEmailId } from '../../../utils/utils'
import _ from 'lodash'
import {
  DEFAULT_EXTERNAL_LINK_OBJECT,
  DEFAULT_LINK_OBJECT,
  DEFAULT_UPLOAD_OBJECT,
  LinkPanelTypesToActionTypes,
  UploadStatuses,
  VISIBLE_LINK_PANEL_SECTIONS,
  VISIBLE_LINK_PANEL_SECTIONS_ADI,
} from './consts/links-settings'
import RemoteApi from '../../../panels/commons/remote-api'
import { PremiumRestriction } from '../../../constants/premium'
import {
  convertPluginsToFormsPlugins,
  findPlugin,
  getPlugins,
  isNativeForm,
  removePlugin,
  updatePlugin,
  getActivePluginFromComponentConnection,
} from '../plugins/utils'
import { SettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import translations from '../../../utils/translations'
import { PAYMENT_STATUS } from '../../../panels/form-settings-panel/components/payment/constants'
import { TABS } from '../../../panels/form-settings-panel/constants'
import Experiments from '@wix/wix-experiments'
import {
  ALWAYS_DISPLAY_MESSAGE,
  MessageDisplayOption,
} from '../../../panels/form-settings-panel/components/submit-message/constants'
import { getSyncedFieldsCount, isFieldSyncable } from '../contact-sync/utils'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { OwnSettingsTabProps } from '../../../panels/form-settings-panel/components/settings/settings'
import { PanelFieldStatus } from '../../../panels/commons/constants/field-statuses'
import { getCountableFields } from '../fields/utils'
import {
  RulesData,
  RULES_UPDATE_STATUS,
} from '../../../panels/form-settings-panel/components/rules/rules-types'
import { Rule } from '@wix/forms-common'
import { OwnRulesTabProps } from '../../../panels/form-settings-panel/components/rules/rules'
import { OwnPaymentTabProps } from '../../../panels/form-settings-panel/components/payment/payment'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone' // import plugin
import utc from 'dayjs/plugin/utc' // import plugin
import customParseFormat from 'dayjs/plugin/customParseFormat' // import plugin
import { PAYMENT_OPTIONS } from '../../../panels/payment-wizard-panel/constants'
import { commonStyles } from '../services/form-style-service'

dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

export interface InitialSettingsPanelData {
  message?: string
  links?: any
  email?: string
  secondEmail?: string
  missingFields?: MissingField[]
  otherFormsNames?: string[]
  isCollectionExists?: boolean
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  productName?: string
  productPrice?: string
}

export interface InitialSubmitPanelData {
  message?: string
  links?: any
  missingFields?: MissingField[]
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  submitMessageOptionsSupported: boolean
  isResponsive: boolean
}

type GetEmailsResponse = {
  email: string
  emailId: string
  failedToSave?: boolean
}[]

export const SETTINGS_API_NAME = 'settings'

const filterPredefinedLabels = (labels) =>
  _.filter(
    labels,
    (label) =>
      label.type === 'USER' ||
      _.includes(['contacts-contacted_me', 'contacts-customers'], label.id),
  )

const apiPath = (funcName) => `${SETTINGS_API_NAME}.${funcName}`

export default class SettingsApi {
  private biLogger: BILogger
  private experiments: Experiments
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  public async loadInitialSubmitPanelData(
    componentRef: ComponentRef,
  ): Promise<InitialSubmitPanelData> {
    const formComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_FORM)
    const formComponentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const { restrictions } = await this.coreApi.premium.getPremiumRestrictions()
    return Promise.all([
      this.getMessage(formComponentRef),
      this.getMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(formComponentRef, formComponentConnection),
      this.getCrucialElements(formComponentRef, formComponentConnection),
      this.coreApi.isRegistrationForm(formComponentRef),
      this.coreApi.isMultiStepForm(formComponentRef),
      this.coreApi.getButtonLabel(componentRef),
      this.determinePaymentStatus(formComponentRef, restrictions),
      this.coreApi.isResponsive(),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        buttonLabel,
        { paymentStatus },
        isResponsive,
      ]) => ({
        successMessage: successMessage.text,
        downloadMessage: downloadMessage.text,
        messagePosition: successMessage.position || downloadMessage.position,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        restrictions,
        buttonLabel,
        formComponentRef,
        formComponentConnection,
        submitComponentRef: componentRef,
        paymentStatus,
        submitMessageOptionsSupported: !isMultiStepForm,
        isResponsive,
      }),
    )
  }

  public async loadPaymentTabData(
    formComponentRef: ComponentRef,
    componentConnection: ComponentConnection,
    {
      restrictions,
      fieldsOnStage,
    }: { restrictions: PremiumRestriction; fieldsOnStage: FormField[] },
  ): Promise<Partial<OwnPaymentTabProps>> {
    try {
      const [{ paymentStatus, paymentStatusChanged }, currency] = await Promise.all([
        this.determinePaymentStatus(formComponentRef, restrictions),
        this.boundEditorSDK.info.getCurrency(),
      ])

      const fetchSingleItemPayload = (): Partial<OwnPaymentTabProps> => {
        const plugins = getPlugins(componentConnection)
        const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
        const items = _.get(paymentPlugin, 'payload.items')
        const products = _.map(items, (item, id) => ({ id, ...item }))

        return {
          productId: _.get(products, '[0].id'),
          productName: _.get(products, '[0].name'),
          productPrice: _.get(products, '[0].price'),
        }
      }

      const fetchItemsListPayload = (): Partial<OwnPaymentTabProps> => {
        const itemsListField = _.find(
          fieldsOnStage,
          (field) => field.role === FIELDS.ROLE_FIELD_ITEMS_LIST,
        )

        if (itemsListField && itemsListField.priceMapping) {
          const items: Product[] = _.map(itemsListField.options, (option: RadioOption) => ({
            id: option.value,
            name: option.label,
            price: itemsListField.priceMapping[option.value],
          }))

          return { items, paymentComponentRef: itemsListField.componentRef }
        }

        return {}
      }

      const selectedPaymentOption = _.get(
        componentConnection,
        'config.selectedPaymentOption',
        PAYMENT_OPTIONS.SINGLE,
      )

      let payload: Partial<OwnPaymentTabProps> = {}

      switch (selectedPaymentOption) {
        case PAYMENT_OPTIONS.LIST:
          payload = fetchItemsListPayload()
          break
        default:
          payload = fetchSingleItemPayload()
      }

      return {
        currency,
        selectedPaymentOption,
        paymentStatus: paymentStatus || PAYMENT_STATUS.GET_STARTED,
        paymentStatusChanged: !!paymentStatusChanged,
        paymentTabDataLoaded: PanelFieldStatus.DONE,
        ...payload,
      }
    } catch (err) {
      return { paymentTabDataLoaded: PanelFieldStatus.FAILED }
    }
  }

  public async loadSettingsTabData(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ): Promise<Partial<OwnSettingsTabProps>> {
    try {
      const [payload, commonStyles] = await Promise.all([
        this.getEmailsAndSiteUsers(componentRef, componentConnection),
        this.coreApi.style.getFieldsCommonStylesGlobalDesign(componentRef),
      ])

      if (!payload) {
        throw new Error('Failed to load settings initial data')
      }

      const { emails, siteUsersData } = payload
      const selectedSiteUsersIds = _.get(componentConnection, 'config.selectedSiteUsersIds')
      const inboxOptOut = _.get(componentConnection, 'config.inboxOptOut')

      return {
        siteUsersData,
        selectedSiteUsersIds,
        inboxOptOut,
        emails,
        commonStyles,
        settingsTabDataLoaded: PanelFieldStatus.DONE,
      }
    } catch (err) {
      return { settingsTabDataLoaded: PanelFieldStatus.FAILED }
    }
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setSelectedSiteUsersIds(
    formComponentRef: ComponentRef,
    selectedSiteUsersIds: string[],
    _biData = {},
  ) {
    return this.setComponentConnection(
      formComponentRef,
      { inboxOptOut: false, selectedSiteUsersIds },
      false,
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.UPDATE_EMAIL_NOTIFICATIONS_OPTION })
  public updateInboxOptOutSelection(formComponentRef: ComponentRef, optOut: boolean, _biData = {}) {
    return this.setComponentConnection(formComponentRef, { inboxOptOut: optOut })
  }

  public async loadInitialSettingsPanelData({
    componentRef,
    componentConnection,
    displayedTab,
    initTabExtraData,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
    displayedTab: any
    initTabExtraData?: any
  }): Promise<Partial<SettingsPanelProps>> {
    const { restrictions, currentAscendPlan } = await this.coreApi.premium.getPremiumRestrictions()

    const plugins = getPlugins(componentConnection)
    const isMultiStepForm = !!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)

    return Promise.all([
      this.getOtherFormsNames(componentRef),
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getMessage(componentRef, ROLE_LIMIT_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getCrucialElements(componentRef, componentConnection),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.isWixChatInstalled(),
      this.boundEditorSDK.environment.getLocale(),
      this.boundEditorSDK.document.info.getTimeZone(),
      this.coreApi.fields.getFieldsSortByXY(componentRef),
      this.coreApi.fetchAppConfig({ formComponentRef: componentRef }),
      this.getLabels({ filterUserLabels: false }),
      isMultiStepForm ? this.coreApi.steps.getSteps(componentRef) : Promise.resolve([]),
      isMultiStepForm
        ? this.coreApi.steps.getCurrentStateIndex(componentRef)
        : Promise.resolve(null),
      isMultiStepForm ? this.coreApi.steps.getLastStepIndex(componentRef) : Promise.resolve(null),
      this.coreApi.getSessionDeletedFields(),
      this.coreApi.isMembersAreaInstalled(),
    ]).then((payload: any) => {
      // TODO: Fix issue with types
      const [
        otherFormsNames,
        successMessage,
        downloadMessage,
        limitMessage,
        links,
        missingFields,
        submitComponentRef,
        isCollectionExists,
        isWixChatInstalled,
        locale,
        timeZone,
        fieldsOnStage,
        appConfig,
        labels,
        stepsData,
        currentStepIndex,
        lastStepIndex,
        sessionDeletedFields,
        isMembersAreaInstalled,
      ] = payload

      const formActivePlugin = getActivePluginFromComponentConnection(componentConnection)

      const limitSubmissionsPlugin = findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)

      const showPaymentTabInFormBuilderPlugin =
        isNativeForm(plugins) || !!findPlugin(plugins, FormPlugin.PAYMENT_FORM)

      const showPaymentTabInGetSubscribersPlugin =
        this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInGetSubscribers') &&
        !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

      const showPaymentTabInRegistrationFormPlugin =
        this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInRegistrationForm') &&
        !!findPlugin(plugins, FormPlugin.REGISTRATION_FORM)

      const showPaymentTab =
        !this.coreApi.isResponsive() &&
        (showPaymentTabInFormBuilderPlugin ||
          showPaymentTabInGetSubscribersPlugin ||
          showPaymentTabInRegistrationFormPlugin)

      const preset = _.get(componentConnection, 'config.preset')
      const formPresetType = _.get(componentConnection, 'config.presetType')

      const countableFields = getCountableFields(fieldsOnStage)

      const syncableFields = countableFields
        .filter((field) => isFieldSyncable(formActivePlugin, field))
        .map(({ crmType, customFieldId }) => ({ crmType, customFieldId }))

      const showEmailMarketingTab =
        this.experiments.enabled('specs.crm.FormsEditorEmailMarketingTabForAll') ||
        !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

      const { secondsToResetForm } = componentConnection.config

      const { limitType, limitValue } = _.get(limitSubmissionsPlugin, 'payload', {})
      const { limitAmount, limitTime } = this._extractLimitProps(limitType, limitValue, timeZone)

      const settingsPanelProps: Partial<SettingsPanelProps> = {
        displayedTab,
        initTabExtraData,
        appConfig,
        isCollectionExists,
        preset,
        formPresetType,
        plugins: convertPluginsToFormsPlugins(plugins),
        formLabelId: componentConnection.config.formLabelId,
        selectedLabels: componentConnection.config.labels || [],
        formName: componentConnection.config.formName,
        lastValidFormName: componentConnection.config.formName,
        otherFormsNames,
        successActionType:
          componentConnection.config.successActionType || SuccessActionTypes.SHOW_MESSAGE,
        secondsToResetForm:
          componentConnection.config.secondsToResetForm || SecondsToResetDefaults.MIN,
        messageDisplayOption: _.eq(secondsToResetForm, ALWAYS_DISPLAY_MESSAGE)
          ? MessageDisplayOption.ALWAYS
          : MessageDisplayOption.CUSTOM_TIME,
        links,
        missingFields,
        successMessage: _.get(successMessage, 'text') || appConfig.content.onSubmitMessage,
        downloadMessage:
          _.get(downloadMessage, 'text') || translations.t('settings.successMessage.download'),
        restrictions,
        submitComponentRef: submitComponentRef,
        messagePosition: successMessage.position || downloadMessage.position,
        isWixChatInstalled,
        selectedTab: displayedTab || TABS.MAIN,
        showPaymentTab,
        locale,
        showEmailMarketingTab,
        fieldsOnStage,
        syncedFieldsCount: getSyncedFieldsCount(syncableFields),
        currentAscendPlan,
        submitMessageOptionsSupported: !!!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM),
        labels: filterPredefinedLabels(labels),
        forbiddenLabels: _.reduce(
          labels,
          (filtered, label) => {
            if (label.type === 'SYSTEM') {
              filtered.push(label.name)
            }

            return filtered
          },
          [],
        ),
        doubleOptIn: _.get(componentConnection, 'config.doubleOptIn'),
        hasSubscriberField: _.some(
          fieldsOnStage,
          (field: FormField) => field.fieldType === FormsFieldPreset.GENERAL_SUBSCRIBE,
        ),
        stepsData,
        currentStepIndex,
        sessionDeletedFields,
        lastStepIndex,
        timeZone,
        limitType: limitType || LimitTypes.NONE,
        limitAmount,
        limitTime,
        limitMessage:
          _.get(limitMessage, 'text') || translations.t('settings.limitMessage.default'),
        isMembersAreaInstalled,
      }

      return settingsPanelProps
    })
  }

  public async createCollectionAndOpenPopup(
    formComponentRef: ComponentRef,
    msid: string,
    extraBiData = {},
  ) {
    const collectionId = await this.coreApi.createManualCollection(formComponentRef, extraBiData)
    this.coreApi.managePanels.openPublishSitePopup(formComponentRef, msid)
    return collectionId
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true,
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig, deepMerge)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(componentRef: ComponentRef, labels: string[], _biData = {}) {
    return this.coreApi.setComponentConnection(componentRef, { labels }, false)
  }

  public async setAsPaymentForm(
    componentRef: ComponentRef,
    {
      currency,
      product,
      extraConfig = {},
    }: { currency: string; product?: Product; extraConfig?: Object },
  ) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins: ComponentPlugin[] = _.get(componentConnection, 'config.plugins')

    let paymentPlugin: ComponentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const createProductData = () => ({
      [product.id]: {
        name: product.name,
        price: product.price,
        quantity: product.quantity || 1,
      },
    })

    const initPaymentPlugin = () => {
      paymentPlugin = {
        id: FormPlugin.PAYMENT_FORM,
        payload: {
          currency,
        },
      }

      if (product) {
        paymentPlugin.payload.items = createProductData()
      }
    }

    const updatePaymentPlugin = () => {
      paymentPlugin.payload.currency = currency

      if (product) {
        paymentPlugin.payload.items = createProductData()
      } else {
        delete paymentPlugin.payload.items
      }
    }

    if (paymentPlugin && paymentPlugin.payload) {
      updatePaymentPlugin()
    } else {
      const collectionId = await this.coreApi.getValidCollectionId(
        componentRef,
        _.get(componentConnection, 'config.collectionId'),
      )
      if (collectionId) {
        await this.coreApi.collectionsApi.addPaymentField(collectionId)
      }
      initPaymentPlugin()
    }

    const updatedPlugins = updatePlugin(plugins, paymentPlugin)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: updatedPlugins,
        ...extraConfig,
      },
      false,
    )
  }

  public async removePlugin(componentRef: ComponentRef, pluginId: FormPlugin) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = getPlugins(componentConnection)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: removePlugin(plugins, pluginId),
      },
      false,
    )
  }

  public async determinePaymentStatus(
    componentRef: ComponentRef,
    restrictions: PremiumRestriction,
  ): Promise<{ paymentStatus: string; paymentStatusChanged: boolean }> {
    const [hasConnectedPayment, componentConnection] = await Promise.all([
      this.coreApi.hasConnectedPayment(),
      this.coreApi.getComponentConnection(componentRef),
    ])

    const plugins = getPlugins(componentConnection)
    const oldPaymentStatus =
      (await _.get(componentConnection.config, 'paymentStatus')) || PAYMENT_STATUS.GET_STARTED
    const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const getPaymentStatus = () => {
      if (!paymentPlugin || !paymentPlugin.payload) {
        return PAYMENT_STATUS.GET_STARTED
      }

      return hasConnectedPayment ? PAYMENT_STATUS.CONNECTED : PAYMENT_STATUS.MISSING_PAYMENT_METHOD
    }

    const paymentStatus = getPaymentStatus()
    const paymentStatusChanged =
      paymentStatus !== oldPaymentStatus &&
      oldPaymentStatus !== PAYMENT_STATUS.MISSING_PAYMENT_METHOD

    await this.coreApi.setComponentConnection(componentRef, { paymentStatus })
    return { paymentStatus, paymentStatusChanged }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async setSuccessActionTypeADI(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    showTitles,
    newSuccessMessage = '',
    _biData = {},
  ) {
    await this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage: newSuccessMessage,
    })
    await this.coreApi.layout.updateFieldsLayoutADI(connectToRef, { showTitles })
  }

  private async _handleActionTypeChange(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    oldSuccessActionType,
    newMessage: string,
  ) {
    const isMultistep = await this.coreApi.isMultiStepForm(formComponentRef)

    switch (oldSuccessActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        await this._removeMessage(formComponentRef)
        if (isMultistep && successActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        await this._removeMessage(formComponentRef, { role: ROLE_DOWNLOAD_MESSAGE })
        if (isMultistep && successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
    }
    switch (successActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.restoreThankYouStep(formComponentRef, newMessage)
        } else {
          await this.coreApi.fields.restoreHiddenMessage(formComponentRef, newMessage)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.restoreThankYouStep(
            formComponentRef,
            newMessage,
            ROLE_DOWNLOAD_MESSAGE,
          )
        } else {
          await this.coreApi.fields.restoreDownloadDocumentMessage(formComponentRef, newMessage)
        }
        break
    }
  }

  private async _setSuccessActionType(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {},
  ) {
    const {
      config: { successActionType: oldSuccessActionType },
    } = await this.coreApi.getComponentConnection(formComponentRef)

    await this.coreApi.setComponentConnection(
      formComponentRef,
      { successActionType, successLinkValue },
      false,
    )

    await this._handleActionTypeChange(
      formComponentRef,
      successActionType,
      oldSuccessActionType,
      newMessage,
    )
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {},
  ) {
    return this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage,
    })
  }

  private _getLimitValue(limitType: LimitTypes, limitAmount: number, limitTime: LimitTime) {
    const valuesMapping = {
      [LimitTypes.AMOUNT]: limitAmount,
      [LimitTypes.TIME]: limitTime,
      [LimitTypes.NONE]: null,
    }

    return {
      savedLimitType: valuesMapping[limitType] ? limitType : LimitTypes.NONE,
      savedLimitValue: valuesMapping[limitType],
    }
  }

  private _extractLimitProps(limitType: LimitTypes, limitValue: LimitValue, timeZone: string) {
    if (limitType === LimitTypes.TIME) {
      const limitTime = limitValue as LimitTime

      const timezoneDate = dayjs.utc(limitTime.date).tz(timeZone)
      return {
        limitAmount: null,
        limitTime: {
          ...limitTime,
          hour: timezoneDate.format(limitTime.hourFormat === '12H' ? 'HH:mmA' : 'HH:mm'),
          date: new Date(timezoneDate.format('YYYY-MM-DD')),
        },
      }
    }
    return {
      limitAmount: (limitType === LimitTypes.AMOUNT ? limitValue : null) as number,
      limitTime: null,
    }
  }

  private async _setLimitSubmissionsPlugin(formComponentRef, limitType, limitAmount, limitTime) {
    let updatedPlugins
    const { savedLimitType, savedLimitValue } = this._getLimitValue(
      limitType,
      limitAmount,
      limitTime,
    )
    const componentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const plugins = getPlugins(componentConnection)
    const limitFormSubmissionsPlugin = findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)

    const pluginShouldExists = limitType !== LimitTypes.NONE && savedLimitValue

    if (pluginShouldExists) {
      updatedPlugins = updatePlugin(plugins, {
        id: FormPlugin.LIMIT_FORM_SUBMISSONS,
        payload: {
          limitType: savedLimitType,
          limitValue: savedLimitValue,
        },
      })
    }

    if (!pluginShouldExists && limitFormSubmissionsPlugin) {
      updatedPlugins = removePlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)
    }

    if (updatedPlugins) {
      await this.coreApi.setComponentConnection(
        formComponentRef,
        { plugins: updatedPlugins },
        false,
      )
    }
  }
  public async onCloseSettingsPanel(formComponentRef: ComponentRef) {
    const componentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const plugins = getPlugins(componentConnection)
    const isMultistep = await this.coreApi.isMultiStepForm(formComponentRef)
    const limitFormSubmissionsPlugin = findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)

    if (!limitFormSubmissionsPlugin) {
      if (isMultistep) {
        await this.coreApi.steps.removeLimitStep(formComponentRef)
      } else {
        const existingMessageRef = await this.coreApi.findComponentByRole(
          formComponentRef,
          ROLE_LIMIT_MESSAGE,
        )
        if (existingMessageRef) {
          await this._removeMessage(formComponentRef, { existingMessageRef })
        }
      }
    }
  }

  @undoable()
  public async setLimitType(
    connectToRef: ComponentRef,
    limitType: LimitTypes,
    {
      limitTime,
      limitAmount,
      newMessage,
      commonStyles,
    }: {
      limitTime?: LimitTime
      limitAmount?: number
      newMessage?: string
      commonStyles?: commonStyles
    } = {},
  ) {
    const isMultistep = await this.coreApi.isMultiStepForm(connectToRef)
    await this._setLimitSubmissionsPlugin(connectToRef, limitType, limitAmount, limitTime)

    if (limitType === LimitTypes.NONE) {
      if (isMultistep) {
        await this.coreApi.steps.removeLimitStep(connectToRef)
      } else {
        await this._removeMessage(connectToRef, { role: ROLE_LIMIT_MESSAGE })
      }
    } else {
      if (isMultistep) {
        await this.coreApi.steps.restoreLimitStep(connectToRef, newMessage)
      } else {
        await this.coreApi.fields.restoreLimitMessage(connectToRef, { commonStyles, newMessage })
      }
    }
  }

  @undoable()
  public setLimitValue(
    connectToRef: ComponentRef,
    limitType: LimitTypes,
    {
      limitTime,
      limitAmount,
    }: {
      limitTime?: LimitTime
      limitAmount?: number
    } = {},
  ) {
    return this._setLimitSubmissionsPlugin(connectToRef, limitType, limitAmount, limitTime)
  }

  private async _removeMessage(
    componentRef: ComponentRef,
    {
      role = ROLE_MESSAGE,
      existingMessageRef,
    }: { role?: string; existingMessageRef?: ComponentRef } = {},
  ) {
    const get = async () => {
      const messageRef =
        existingMessageRef || (await this.coreApi.findComponentByRole(componentRef, role))
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      return { messageRef, messageLayout }
    }
    const { messageRef, messageLayout } = await get()
    if (!messageLayout) {
      return
    }
    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, isADI = false) {
    const {
      config: { successLinkValue, successActionType },
    } = await this.coreApi.getComponentConnection(componentRef)

    let linkObject
    try {
      linkObject = await this.boundEditorSDK.editor.openLinkPanel(<any>{
        value: successLinkValue,
        visibleSections: isADI ? VISIBLE_LINK_PANEL_SECTIONS_ADI : VISIBLE_LINK_PANEL_SECTIONS,
      })
    } catch (e) {
      return {
        chosenLinkType: null,
        successLinkText: null,
        linkObject: null,
      }
    }

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const chosenLinkType =
      linkObject && linkObject.type
        ? LinkPanelTypesToActionTypes[linkObject.type]
        : successActionType
    await this.setSuccessActionType(componentRef, chosenLinkType, linkObject)

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)

    return {
      chosenLinkType: chosenLinkType,
      successLinkText,
      linkObject,
      successLinkType,
      successLinkSubType,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.CLICK_UPLOAD_BUTTON })
  public async updateDownloadSection(componentRef: ComponentRef, _biData) {
    let {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    try {
      const uploadedObject = (await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: <any>MediaTypes.DOCUMENT,
        isMultiSelect: false,
      })) as DocumentMediaResult[]

      if (uploadedObject) {
        successLinkValue.docId = _.head(uploadedObject).uri
        successLinkValue.name = _.head(uploadedObject).title
        successLinkValue.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      successLinkValue.status = UploadStatuses.UPLOAD_FAILED
    }
    await this.setComponentConnection(
      componentRef,
      {
        successLinkValue: successLinkValue,
      },
      false,
    )
    return successLinkValue
  }

  public async getSubmitOptionsData(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ) {
    const {
      config: { successActionType, successLinkValue },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const getText = (linkObj) => this.boundEditorSDK.editor.utils.getLinkAsString({ link: linkObj })

    const links = {
      [SuccessActionTypes.LINK]: {
        text: await getText(DEFAULT_LINK_OBJECT),
        object: DEFAULT_LINK_OBJECT,
      },
      [SuccessActionTypes.EXTERNAL_LINK]: {
        text: await getText(DEFAULT_EXTERNAL_LINK_OBJECT),
        object: DEFAULT_EXTERNAL_LINK_OBJECT,
      },
      [SuccessActionTypes.DOWNLOAD_DOCUMENT]: {
        object: DEFAULT_UPLOAD_OBJECT,
      },
    }

    if (successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
      links[successActionType] = {
        text: await getText(successLinkValue),
        object: successLinkValue,
      }
    }
    return links
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(
    componentRef: ComponentRef,
    {
      emailIndex,
      newEmail,
      currentEmails,
    }: {
      emailIndex: number
      newEmail: string
      currentEmails: GetEmailsResponse
    },
    _biData = {},
  ): Promise<GetEmailsResponse> {
    try {
      if (_.get(currentEmails, `[${emailIndex}].email`) === newEmail) {
        return currentEmails
      }

      let newEmailId = null

      if (!_.isEmpty(newEmail)) {
        const { emailId } = await this.remoteApi.insertEmail(newEmail)
        newEmailId = emailId
      } else {
        if (emailIndex === 0) {
          newEmailId = EMPTY_EMAIL_ID
        }
      }

      const newEmails = _.cloneDeep(currentEmails)
      newEmails[emailIndex] = { emailId: newEmailId, email: newEmail }
      const emailIds = newEmails.map((email) => email.emailId)
      await this.setComponentConnection(componentRef, { emailIds }, false)

      return newEmails
    } catch (err) {
      const newEmails = _.cloneDeep(currentEmails)
      newEmails[emailIndex] = { emailId: 'INVALID', email: newEmail, failedToSave: true }
      return newEmails
    }
  }

  public async deleteInactiveEmails(
    componentRef: ComponentRef,
    emails: GetEmailsResponse,
    emailsLimit: number,
  ): Promise<GetEmailsResponse> {
    if (emails.length <= emailsLimit) {
      return emails
    }

    const filteredEmails = _.filter(
      emails,
      (email) =>
        email !== null &&
        _.get(email, 'emailId') !== EMPTY_EMAIL_ID &&
        _.get(email, 'emailId') !== '',
    )

    const updatedEmails = filteredEmails.slice(0, emailsLimit)
    const emailIds = updatedEmails.map((email) => email.emailId)
    await this.setComponentConnection(componentRef, { emailIds }, false)

    return updatedEmails
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(
    componentRef: ComponentRef,
    { newMessage, role = ROLE_MESSAGE },
    _biData = {},
  ) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })

    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data, newMessage }),
    })
  }

  public async getSubmitButtonLabel(componentRef: ComponentRef) {
    const submitButtonRef = await this.getSubmitComponentRef(componentRef)
    return this.coreApi.getButtonLabel(submitButtonRef)
  }

  public getSubmitComponentRef(componentRef: ComponentRef): Promise<ComponentRef> {
    return this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
  }

  @undoable()
  public async updateSubmitButtonLabel(componentRef: ComponentRef, newLabel: string, biData) {
    const submitButtonRef = await this.getSubmitComponentRef(componentRef)
    return this.coreApi.updateButtonLabel(submitButtonRef, newLabel, { startBi: biData })
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getLabels({ filterUserLabels = true } = {}) {
    return this.remoteApi
      .getLabels()
      .then((labels) => (filterUserLabels ? filterPredefinedLabels(labels) : labels))
      .catch(() => null)
  }

  public async getMessage(
    componentRef: ComponentRef,
    role: string = ROLE_MESSAGE,
  ): Promise<Message> {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    if (!messageRef) {
      return { text: '' }
    }
    const [{ data, layout }] = await this.boundEditorSDK.components.get({
      componentRefs: messageRef,
      properties: ['data', 'layout'],
    })
    const { x, y } = layout
    return { text: _.unescape(innerText(data.text)), position: { x, y } }
  }

  private async _getEmails(emailIds: string[]): Promise<GetEmailsResponse> {
    const isThereNonEmptyEmail = _.some(emailIds, isNotEmptyEmailId)

    if (!isThereNonEmptyEmail) {
      return emailIds.map((emailId) => ({ emailId, email: '' }))
    }

    return this.remoteApi.getEmailsById(emailIds)
  }

  @absorbException('settings-panel')
  public async getEmailsAndSiteUsers(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<{ emails: GetEmailsResponse; siteUsersData: SiteUserData[] }> {
    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = _.get(connection, 'config')
    const emailId = _.get(config, 'emailId')
    const secondEmailId = _.get(config, 'secondEmailId')
    const emailsIds = _.get(config, 'emailIds')
    const actualEmailsIds: string[] = emailsIds || [emailId, secondEmailId]

    const [emails, siteUsersData] = await Promise.all([
      this._getEmails(actualEmailsIds),
      this.remoteApi.getSiteUsersData(),
    ])

    const owner = _.find(siteUsersData, (user) => user.isOwner)

    const [firstEmail, ...restOfEmails] = emails
    let actualFirstEmail = firstEmail

    if (_.get(firstEmail, 'emailId') !== EMPTY_EMAIL_ID && !_.get(firstEmail, 'email')) {
      actualFirstEmail = { emailId: null, email: _.get(owner, 'email', '') }
    }

    return {
      emails: [actualFirstEmail, ...restOfEmails.filter((email) => email.email)],
      siteUsersData,
    }
  }

  public async getCrucialElements(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<MissingField[]> {
    let connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const { controllerRef, config } = connection

    const plugins = _.get(config, 'plugins')
    const successActionType = _.get(config, 'successActionType')
    const limitType = _.get(config, 'limitType')

    const pluginApi = this.coreApi.plugins.withPlugins(plugins)

    if (pluginApi) {
      //TODO: Thing about different solution
      const funcName = 'getCrucialElements'

      if (pluginApi.supportApi(apiPath(funcName))) {
        return pluginApi.callApi(apiPath(funcName), componentRef, connection)
      }
    }

    // TODO: Merge this with above using the plugin system solution

    let isPreviousButtonMissingPromise = Promise.resolve(null)
    let isNextButtonMissingPromise = Promise.resolve(null)
    let isLimitMessageMissingPromise = Promise.resolve(null)

    if (!!_.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })) {
      isPreviousButtonMissingPromise = this.coreApi.steps.isPreviousButtonMissing(componentRef)
      isNextButtonMissingPromise = this.coreApi.steps.isNextButtonMissing(componentRef)
    }

    if (!!findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)) {
      isLimitMessageMissingPromise = this.coreApi.isFieldMissingByRole(
        componentRef,
        ROLE_LIMIT_MESSAGE,
        TABS.SETTINGS,
      )
    }

    const isMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.SHOW_MESSAGE
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE, TABS.SUBMIT_MESSAGE)
        : Promise.resolve(null)

    const isDownloadMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT
        ? this.coreApi.isFieldMissingByRole(
            componentRef,
            ROLE_DOWNLOAD_MESSAGE,
            TABS.SUBMIT_MESSAGE,
          )
        : Promise.resolve(null)

    const missingFields = await Promise.all([
      isMessageFieldMissingPromise,
      isDownloadMessageFieldMissingPromise,
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.isEmailFieldMissing(controllerRef),
      isPreviousButtonMissingPromise,
      isNextButtonMissingPromise,
      isLimitMessageMissingPromise,
    ])

    return _.filter(missingFields)
  }

  public async getOtherFormsNames(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<string[]> {
    const { controllerRef } =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef,
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async (formControllerRef) => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      }),
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {},
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels, collectionId },
    } = await this.coreApi.getComponentConnection(connectToRef)
    const updateActions = [Promise.resolve(null), Promise.resolve(null)]

    if (collectionId) {
      const validCollectionId = await this.coreApi.getValidCollectionId(connectToRef, collectionId)
      updateActions[0] = this.coreApi.collectionsApi.updateCollectionName(
        validCollectionId,
        formName,
      )
    }

    const shouldChangeTag = !formLabelId || _.includes(labels, formLabelId)
    const shouldCreateTagInsteadOfUpdate = !formLabelId || _.includes(formLabelId, '/') // backwards compatibility to replace system labels with user labels (ex: contacts_server/subscribers)

    if (shouldChangeTag) {
      if (shouldCreateTagInsteadOfUpdate) {
        updateActions[1] = this.remoteApi.createTag(formName)
      } else {
        updateActions[1] = this.remoteApi.updateTag(formLabelId, formName)
      }
    }

    if (shouldChangeTag && shouldCreateTagInsteadOfUpdate) {
      const [, { id: newFormLabelId }] = await Promise.all(updateActions)
      const newLabels = _.filter(
        [...(labels || []), newFormLabelId],
        (labelId) => labelId !== formLabelId,
      )

      await this.coreApi.setComponentConnection(connectToRef, {
        formLabelId: newFormLabelId,
        labels: newLabels,
      })
    } else {
      await Promise.all(updateActions)
    }
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if (_.get(linkObject, 'url')) {
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef: ComponentRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType: string, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }

  public async fixFormSync(componentRef: ComponentRef): Promise<void> {
    const fields: FormField[] = await this.coreApi.fields.getFieldsSortByXY(componentRef)
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    const validCollectionId = await this.coreApi.getValidCollectionId(componentRef, collectionId)
    if (!validCollectionId) {
      return
    }

    const collectionFieldKeyToFields: { [key: string]: FormField[] } = fields.reduce(
      (acc, field) => {
        const collectionFieldKey = field.collectionFieldKey
        if (collectionFieldKey) {
          acc[collectionFieldKey] = (acc[collectionFieldKey] || []).concat(field)
        }
        return acc
      },
      {},
    )
    const schema = await this.coreApi.getSchema(validCollectionId)
    const fieldsToUpdate: FormField[] = fields
      .filter(({ fieldType }) => allowCollectionSync(fieldType))
      .filter((field) => {
        return (
          _.findIndex(
            collectionFieldKeyToFields[field.collectionFieldKey],
            (_field) => _field.componentRef.id === field.componentRef.id,
          ) > 0 ||
          !field.collectionFieldKey ||
          !schema.fields[field.collectionFieldKey]
        )
      })
    fieldsToUpdate.forEach((field) => {
      field.collectionFieldKey = this.coreApi.fields.getCollectionFieldKey(
        { crmLabel: field.crmLabel },
        fields,
      )
    })
    await Promise.all([
      this.coreApi.collectionsApi.addFieldsToCollection(validCollectionId, fieldsToUpdate),
      ...fieldsToUpdate.map((field) =>
        this.coreApi.setComponentConnection(field.componentRef, {
          collectionFieldKey: field.collectionFieldKey,
        }),
      ),
    ])
  }

  public async updateUseControllerId(componentRef: ComponentRef): Promise<void> {
    await this.coreApi.setComponentConnection(componentRef, { useControllerId: true })
  }

  public async loadRules(controllerRef: ComponentRef): Promise<RulesData> {
    try {
      const rules = await this.coreApi.get<Rule[]>(controllerRef, 'rules')

      return {
        rules: rules || [],
        rulesUpdateStatus: RULES_UPDATE_STATUS.SUCCESS,
      }
    } catch (err) {
      return {
        rules: null,
        rulesUpdateStatus: RULES_UPDATE_STATUS.FAILED,
      }
    }
  }

  public async loadRulesTabData(
    componentConnection: ComponentConnection,
  ): Promise<Partial<OwnRulesTabProps>> {
    try {
      const rulesData = await this.loadRules(componentConnection.controllerRef)

      if (rulesData.rulesUpdateStatus === RULES_UPDATE_STATUS.FAILED) {
        throw new Error('Failed to load rules initial data')
      }

      return {
        rulesData,
        rulesTabDataLoaded: PanelFieldStatus.DONE,
      }
    } catch (err) {
      return { rulesTabDataLoaded: PanelFieldStatus.FAILED }
    }
  }
}
